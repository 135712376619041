

var $window = $(window);
var wW = $window.width();


/* ==========================================================================
beforeReady js (在 smoothState ajax跳轉頁面後，必須重新呼叫js (beforeReady、)
==========================================================================*/

function beforeReady() {
  
  /*  首頁動畫
  -------------------------------------------------- */
  var ua = window.navigator.userAgent;
  var is_ie = /MSIE|Trident/.test(ua);

  if (document.getElementById("index") !== null) {
    var controller_index = new ScrollMagic.Controller();

    //場景一
    let s1_slick = $("#index .slick "),
      s1_logo_img = $("#index .logo"),
      s1_gradiant = $("#gradiant"),
      s1_sub40 = $("#index .subtitle .f40"),
      s1_sub28 = $("#index .subtitle .f28"),
      // s1_nav = $("#index #nav"),
      // s1_list = $("#index #nav a"),
      s1_dwon = $("#index .scroll"),
      s1_btn = $("#dashboard .gasp"),
      s1_plus = $("#dashboard #js-plus"),
      s1_tl = new TimelineLite(),
      s1_chars40 = new SplitText(s1_sub40, { type: "words,chars" }).chars,
      s1_chars28 = new SplitText(s1_sub28, { type: "words,chars" }).chars;

    s1_tl
      .to(s1_slick, 3, { autoAlpha: 1 }, 0)
      .from(s1_gradiant, 3, { autoAlpha: 0 }, 1)
      .staggerFrom(s1_chars40, 0.8, { autoAlpha: 0, y: 15, scale: 0.9, ease: Power1.easeInOut }, 0.12, 1.5)
      .staggerFrom(s1_chars28, 0.3, { autoAlpha: 0, y: 0, scale: 0.9, ease: Power1.easeInOut }, 0.08, 2)
      .from(s1_dwon, 0.8, { autoAlpha: 0, scale: 0.9, transformOrigin: "center center", ease: Power1.easeIn }, "-=0.6");

    if ($(window).width() > 991) {
      s1_tl.staggerFromTo(s1_btn, 0.4, { autoAlpha: 0, y: -20, scale: 0.7 }, { autoAlpha: 1, y: 0, scale: 1 }, 0.2, "-=2");
    } 
    // else {
    //   s1_tl.from(s1_plus, 0.4, { autoAlpha: 0, y: -20, scale: 0.7 }, "-=3");
    // }


    //場景二
    $(".imgLiquid").imgLiquid();

    let s2_scrollTL = new TimelineLite(),
      s2_card = $(".feature .card"),
      s2_f40 = $(".feature .f40"),
      s2_f36 = $(".feature .f36"),
      s2_f20 = $(".feature .f20"),
      s2_text = $(".feature p");

    s2_scrollTL
      .from(s2_card, 1, { autoAlpha: 0 })
      .from(s2_f36, 1, { autoAlpha: 0, y: 20 }, "-=0.5")
      .from(s2_f40, 1, { autoAlpha: 0, y: -10 }, 1)
      .from(s2_f20, 1, { autoAlpha: 0, y: 10 }, 1);
    // .from(s2_text, 1, { autoAlpha: 0, onComplete: mouseEvent},1.5);

    let s2_scroll = new ScrollMagic.Scene({
      triggerElement: "#index .feature",
      triggerHook: 0.9,
      reverse: false
    })
      .setTween(s2_scrollTL)
      .addTo(controller_index);

    //場景三
    let s3_tl = new TimelineLite(),
      s3_story = $("#index .stories"),
      s3_title = s3_story.find(".title"),
      s3_f40 = s3_title.find(".f40"),
      s3_f36 = s3_title.find(".f36"),
      s3_f20 = s3_title.find(".f20"),
      s3_groupParent = $("#index .stories .group"),
      s3_group1 = s3_groupParent[0],
      s3_group2 = s3_groupParent[1],
      s3_txt1 = $(s3_group1).find(".slick"),
      s3_txt2 = $(s3_group2).find(".slick");

    s3_tl
      .from(s3_f36, 2, { autoAlpha: 0, y: 20 })
      .from(s3_f40, 1, { autoAlpha: 0, y: -15 }, 1)
      .from(s3_f20, 1, { autoAlpha: 0, y: 20 }, 1)
      .from(s3_group1, 1, { y: 30, autoAlpha: 0 }, "-=1")
      .from(s3_group2, 1, { y: -30, autoAlpha: 0 }, "-=1");

    let s3_scroll = new ScrollMagic.Scene({
      triggerElement: "#index .stories",
      triggerHook: 0.9,
      reverse: false
      // duration: 500,
    })
      .setTween(s3_tl)
      .addTo(controller_index);

    //場景四
    let s4_tl = new TimelineLite(),
      s4_cusine = $(".cusine"),
      s4_center = s4_cusine.find(".center"),
      s4_img = s4_cusine.find(".video_block"),
      s4_introBgc = s4_cusine.find(".intro_block "),
      s4_f40 = s4_cusine.find(".f40"),
      s4_lh20 = s4_cusine.find(".lh20"),
      s4_btn = s4_cusine.find(".btn.plus");

    s4_tl
      .from(s4_center, 2, { autoAlpha: 0 })
      .from(s4_img, 2, { autoAlpha: 0 }, 1)
      .from(s4_introBgc, 2, { autoAlpha: 0 }, 1)
      .from(s4_f40, 1, { autoAlpha: 0, y: 15 }, 1.6)
      .from(s4_lh20, 1, { autoAlpha: 0, y: -20 }, 2)
      .from(s4_btn, 1, { autoAlpha: 0, y: -20 }, 2.4);

    let s4_scroll = new ScrollMagic.Scene({
      triggerElement: "#index .cusine",
      triggerHook: 0.9
      // reverse: false
    })
      .setTween(s4_tl)
      .addTo(controller_index);

    //場景五 (新聞中心)
    let s5_scrollTL = new TimelineLite(),
      s5_news = $("#index .news"),
      s5_title = s5_news.find(".sub"),
      s5_img = s5_news.find(".img-group a"),
      s5_list = s5_news.find(".content li"),
      s5_btn = s5_news.find(".content > a");

    s5_scrollTL
      .from(s5_title, 1.5, { autoAlpha: 0, y: "10%" })
      .staggerFrom(s5_list, 0.6, { autoAlpha: 0, x: 10, ease: Power1.easeInOut }, 0.2, 0.5)
      .from(s5_btn, 0.6, { autoAlpha: 0, x: 10 })
      .staggerFrom(s5_img, 1, { autoAlpha: 0, scale: 0.5, ease: Back.easeOut.config(1.4) }, 0.2, 0.8);

    let s5_scroll = new ScrollMagic.Scene({
      triggerElement: "#index .news",
      triggerHook: 0.75,
      reverse: false
    })
      .setTween(s5_scrollTL)
      .addTo(controller_index);

    //場景六 (footer)
    let s6_scrollTL = new TimelineMax(),
      s6_footer = $("#footer"),
      s6_flower1 = s6_footer.find(".flower1"),
      s6_flower2 = s6_footer.find(".flower2"),
      s6_list = s6_footer.find(".sitemap__content > li"),
      s6_call = s6_footer.find(".sitemap__call > li"),
      s6_address = s6_footer.find(".address");

    s6_scrollTL
      .fromTo(s6_flower1, 3, { x: 220, y: -150, autoAlpha: 0, rotation: 20 }, { x: 150, y: -100, autoAlpha: 1, rotation: 0, ease: Power3.easeOut })
      .fromTo(s6_flower2, 3, { x: -180, y: 200, autoAlpha: 0, rotation: 40 }, { x: -120, y: 162, autoAlpha: 1, rotation: 0, ease: Power3.easeOut }, 0.2)
      .staggerFrom(s6_list, 0.6, { autoAlpha: 0, x: 10, ease: Power1.easeInOut }, 0.2, 0.6)
      .staggerFrom(s6_call, 0.6, { autoAlpha: 0, x: 10, ease: Power1.easeInOut }, 0.2, 0.8)
      .from(s6_address, 0.6, { autoAlpha: 0, y: 10 }, 1);

    let s6_scroll = new ScrollMagic.Scene({
      triggerElement: "#footer",
      triggerHook: 0.8,
      reverse: false
    })
      .setTween(s6_scrollTL)
      .addTo(controller_index);
    //bug 在播放footer動畫後，跳到其他頁面，會有scrollmagic警示，但不會影養執行

    //廣告視窗的結束後才讓首頁動畫開始
    $(document).on("lity:open", function(event, instance) {
      setTimeout(() => {
        // nav_tl.pause();
        s1_tl.pause();
        s2_scrollTL.pause();
      }, 200);
    });
    $(document).on("lity:close", function(event, instance) {
      // nav_tl.play();
      s1_tl.play();
      s2_scrollTL.play();
      $("body").trigger($.Event("keydown", { keyCode: 27 }));
    });

    //若已經看過，則不要在觸發
    // var triggerAD = sessionStorage.getItem("notTriggerAD");

    // if (triggerAD !== "true") {
    // }
    // sessionStorage.setItem("notTriggerAD", "true");
    
    // 廣告視窗 (二者順序不對會產生短暫破圖，待釐清)
    $("a[href='#ADslick']").trigger("click");
    $(".slick.load").slick({
      arrows: true,
      dots: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false
    });


  }//index end



  /*  各頁面landing動畫
    -------------------------------------------------- */

    if (($(window).width() <= 991)) {
      TweenLite.set($(".banner .mask"), {autoAlpha: 0});
    }

    if (($(window).width() > 991) && 
      ((document.querySelector("#index") == null) &&  (document.querySelector("#search") == null))    
    ){

    var landing_title = $(".banner h2"),
        landing_mask = $(".banner .mask"),
        landing_nav = $("#nav"),
        landing_banner = $(".banner"),
        landing_sub = $(".banner .group"),
        landing_gasp = $("#dashboard .gasp"),
        landing_forward = $(".foreword");
        // landing_submenu = $(".submenu"),
        // landing_content = $("#content");

    var landingTL = new TimelineLite(),
        landingSplitText = new SplitText(landing_title, { type: "words,chars" }),
        landingChars = landingSplitText.chars;

    landingTL
      // .from(landing_nav, 1, { autoAlpha: 0, y: "-70%", ease: Power0.easeNone })
      .from(landing_banner, 2, { autoAlpha: 0, y: "-5%", ease: Power2.easeInOut }, 0.3)
      // .to(landing_banner, 2, { scale: 1.06, ease: Power1.easeOut}, 0.3)
      .to(landing_mask, 3.6, { scaleY: 0, transformOrigin: "center bottom", ease: Power3.easeOut,}, "-=2")
      .staggerFrom(landingChars, 0.6, { autoAlpha: 0, y: 10, ease: Power1.easeIn }, 0.2, 1.2)
      .from(landing_sub, 0.6, { autoAlpha: 0, y: 10 },2)
      .from([landing_forward], 1, { autoAlpha: 0 },2.2);
      // .staggerFromTo(landing_gasp, 0.4, { y: -20, scale: 0.7 }, { autoAlpha: 1, y: 0, scale: 1 }, 0.2, "-=1");


  }

  if (($(".section")[0] !== undefined ) && ( $(window).width() > 991 ) ) {

    var section_section = $(".section"),
      controller_section = new ScrollMagic.Controller();

    section_section.each(function () {
      var _this = $(this),
        section_f40 = _this.find(".content .f40"),
        section_bgc = _this.find(".bgc"),
        section_border = _this.find(".border > div"),
        section_other = _this.find(".border ~ *"),
        section_scrollTL = new TimelineLite();

      section_scrollTL
        .from(section_bgc, 0.7, { autoAlpha: 0, ease: Power1.easeInOut })
        .staggerFrom(section_f40, 0.8, { autoAlpha: 0, y: 100 }, 0.2, 0.1)
        .from(section_border, 0.4, { autoAlpha: 0 }, 0.2)
        .staggerFrom(section_other, 0.6, { autoAlpha: 0, y: 15 }, 0.1, "-=0.4");

      let section_scroll = new ScrollMagic.Scene({
        triggerElement: this,
        triggerHook: 0.8,
      }).setTween(section_scrollTL).addTo(controller_section);
    });
  }

  /* ==========================================================================
          plugin slick init
  ==========================================================================*/


  /*  plugin init
    -------------------------------------------------- */
  $(".imgLiquidFill").imgLiquid();
  
  let landing_slider = $('.slick.landing');
  if (!landing_slider.hasClass('slick-initialized')) {

    if (!is_ie) {
      $('.slick.landing').on('init', function (event, slick, currentSlide, nextSlide) {
        let target = $(this).find('[data-slick-index="0"]');
        TweenLite.to(target, 10, { scale: 1.1 })
      });
    }

    $('.slick.landing').slick({
      arrows: false,
      dots: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 3000,
      fade: true,
    });
    
    if (!is_ie) {
      $('.slick.landing').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        let target = $(this).find("[data-slick-index=" + nextSlide + "]");
        TweenLite.to( target, 10, { scale: 1.1})
      });
    }
  }



  $('.slick.basic').slick({
    arrows: true,
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
  });

  
  $(".slick.seven, .slick.eight").slick({
    arrows: true,
    dots: false,
    infinite: false,
    autoplay: false,
    responsive: [
      {
        breakpoint: 9999,
        settings: "unslick"
      },
      {
        breakpoint: 1450,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2
        }
      }
    ]
  });



  var $submenu_slider = $('.submenu .slick:not(.seven):not(.eight)'),
      settings = {
        arrows: true,
        dots: false,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 2,
        autoplay: false,
      };

  $submenu_slider.slick(settings);

  $(window).on('resize', function () {
    if ($(window).width() > 991) {
      if ($submenu_slider.hasClass('slick-initialized')) {
        $submenu_slider.slick('unslick');
      }
      return;
    }

    if (!$submenu_slider.hasClass('slick-initialized')) {
      return $submenu_slider.slick(settings);
    }
  });

  $(window).trigger("resize");  







  //submenu slick 初始化 及定位
  var indexOfSubmenu;
  var dashPos = window.location.pathname.indexOf("-");
  var $submenu_all = $('.submenu .slick');

  if (dashPos == -1) {
    indexOfSubmenu = 1;
  } else {
    indexOfSubmenu = window.location.pathname.charAt(dashPos + 1);
  }

  //slick 無法 goto 到最後一個 因此要指定到前一個
  var slideLength = $(".submenu .slick-slide").length;
  indexOfSubmenu == slideLength ? (indexOfSubmenu = indexOfSubmenu - 2) : (indexOfSubmenu = indexOfSubmenu - 1);
  // console.log(slideLength);
  // console.log(indexOfSubmenu);
  
  if ($submenu_all.hasClass('slick-initialized')) {
    $submenu_all.slick('slickGoTo', (indexOfSubmenu), true);
  }
  
  

} //beforeReady end
beforeReady();


/* ==========================================================================
js part 2  (在 smoothState 跳轉頁面後，再ajax載入)
==========================================================================*/
function readyJs() {

  var $window = $(window);



/* ==========================================================================
        通用元件 js
 ==========================================================================*/

  // dashboard、menu 1100 以下以toogle 顯示


  
  function renderNav() {
    if (  $(window).width() < 1100) {
      $("#dashboard .icon-cart.mobile").insertAfter("#nav .logo");
      $("#dashboard .icon-food").insertAfter("#nav .logo");
    } 
  }

  if (document.getElementById("index") == null) {

  }
  $window.on("resize", renderNav);




  
  if (  $(window).width() < 1100) {

    var $dashboard = $("#dashboard");
    var $dashboardBtn = $("#dashboard > .gasp");
    var $dashboardToggle = $("#js-plus");
    var $dashboardGotop = $(".go-top");
    var $menuCover = $("#nav .scrollbarY");

    var dashboardTL = new TimelineMax({ paused: true, reversed: true, yoyo: true });
    
    dashboardTL.staggerTo($dashboardBtn, 0.3, { y: 0, autoAlpha: 1 }, 0.02);
    
    $dashboardToggle.click(function() {
      dashboardTL.reversed() ? dashboardTL.play() : dashboardTL.reverse().timeScale(2);
    });
    
    
    
    // var menu__bgc = $("#menu__bgc");
    // var menu__toggle = $(".menu__toggle");
    // var menu__one = $(".menu__toggle .one");
    // var menu__two = $(".menu__toggle .two");
    // var menu__three = $(".menu__toggle .three");
    
    
    var menuTL = new TimelineLite({ paused: true, reversed: true, yoyo: true });
    menuTL
      // .to(menu__bgc, 0.3, { autoAlpha: 1 })
      .call(function () { $("#nav .menu").toggleClass("expend");})
      .to($dashboard, 0.3, { autoAlpha: 0 }, 0)
      .to($menuCover, 1, { xPercent: -100, ease: Power2.easeOut })
      .from($menuCover, 1, { autoAlpha: 0, ease: Power2.easeOut }, 0);
      
    $("#nav .menu__toggle").click(function() {
      menuTL.reversed() ? menuTL.play() : menuTL.reverse().timeScale(2);
    });


    $("#menu__bgc").on("click", function () {
      menuTL.reverse().timeScale(2);
    });

    $("#nav .drop a").click(function (e) {
      e.preventDefault();
    });

    $("#nav .drop").each(function () {

      $(this).click(function () {
        var dropdwon = $(this).next("ul");
        var dropUp = $(".menu__cover >  .nostyle").not(dropdwon);
        dropUp.slideUp(300);
        dropdwon.animate({ opacity: "toggle", height: "toggle" }, 300);
      });
      
    });

  }





  
  if (wW > 1100 ) {
    $("#dashboard  > *").hover(function () {
      $(this).toggleClass("expend");
    });

  } else {
    $(".gasp").click(function() {
      
      $(".gasp").removeClass("expend");
      $("#dashboard .cover").show().click(function () {
        $(".gasp").removeClass("expend");
        $(this).hide();
      });

      $(this).addClass("expend");
    });
  }
  



  
  /*  navbar underline animaiton
    -------------------------------------------------- */


  var dotPos;
  if (window.location.pathname.lastIndexOf("-") !== -1) {
    dotPos = window.location.pathname.indexOf("-");
  } else {
    dotPos = window.location.pathname.indexOf(".");
  }

  var pageURL = window.location.pathname.slice(1, dotPos);              //選取應當出現底線之 a (當前頁面)  
  if (pageURL == "" ) {pageURL = "index";}
  // console.log(pageURL);

  
  var anchor = document.querySelector(`a[href="${pageURL}.html"]`);
  var left, width, padding, parentLeft;

  renderData();
  changeUnderline();
  $window.on("resize", renderData);  //在畫面拉大、拉小時，更新絕對定位資料


  function renderData() {
    left = anchor.getBoundingClientRect().left;
    width = anchor.offsetWidth;
    padding = window.getComputedStyle(anchor, null).getPropertyValue("padding-left").slice(0, 2)
    parentLeft = document.querySelector("#nav .menu ul").getBoundingClientRect().left;
  }

  function changeUnderline() {//用絕對定位設定 underlin 位置
    if (parseInt(padding) !== 0) {//在首頁會有跳到最左
      $("#underline").css({
        width: width - (padding * 2),
        left: (left - parentLeft + parseInt(padding))
      });
    }
  }

  $("#nav .menu a").hover(function () {//滑鼠hover時設定位置
    var _this = $(this)[0];
    left = _this.getBoundingClientRect().left;
    width = _this.offsetWidth;
    padding = window.getComputedStyle(_this, null).getPropertyValue("padding-left").slice(0, 2);
    changeUnderline();
  },function () {
    renderData();
    changeUnderline();
  });


  /*  scroll
    -------------------------------------------------- */
  $(".go-top").each(function () {
    $(this).click(function () {
      $("html,body").animate({ scrollTop: 0}, 1500, 'easeInOutCubic');
      return false;
    });
  });

  $(".wp").on('mousewheel', function () {
    $('html,body').stop();
  });



/* ==========================================================================
          各頁面單獨 js
==========================================================================*/



/*  index
-------------------------------------------------- */
// var $window = $(window);

if (document.querySelector("#index") !== null) {  

    /*  index fixed navbar  、特殊樣式
      -------------------------------------------------- */
    var nav = document.getElementById("nav");
    nav.classList.add("circleType");

    // var navPadTop = $(".navPad").offset().top;
    var landingHeight = $(".landing-wrap").height();

    var fixNav = function () {
      if ($window.scrollTop() > landingHeight) {
        
        nav.classList.remove("circleType");
        nav.classList.remove("middleType");
      } else {
        
        nav.classList.add("circleType");
        nav.classList.add("middleType");
      }
    };

    $window.on("scroll resize", fixNav);



    /*  index scroll
      -------------------------------------------------- */
    $(".scroll-down").each(function () {
      $(this).click(function () {
        $("html,body").animate({
          scrollTop: $("#scroll-here").offset().top // - $("#header").height()
        }, 1500, 'easeInOutCubic');
        return false;
      });
    });
  

  } //index page end

  
  



  // submenu 在手機版時會、縮減文、斷行
  String.prototype.breakAt = function () {
    if (this.length >= 7) {
      return this.substr(0, 4) + "<br/>" + this.substr(4);
    } else if (this.length >= 5) {
      return this.substr(0, 3) + "<br/>" + this.substr(3);
    } else {
      return this.substr(0, 2) + "<br/>" + this.substr(2);
    }
  };

  function breakMenu() {
    if ($(window).width() <= 500) {

      let textArray = $(".submenu .slick .f18");

      if ($(".submenu .slick .f18:first-child").data("long") == undefined) {//讓在拉伸視窗時，可以回覆較長的名稱。
        for (let i = 0; i < textArray.length; i++) {
          textArray[i].dataset.long = textArray[i].innerText;
          // console.log(textArray[i].dataset.long);
        }
      }
      for (let i = 0; i < textArray.length; i++) {
        textArray[i].innerHTML = textArray[i].dataset.abbr.breakAt();
      }
      
    } else if ($(".submenu .slick .f18:first-child").data("long") !== undefined) {
        let textArray = $(".submenu .slick .f18");
  
        for (let i = 0; i < textArray.length; i++) {
          textArray[i].innerHTML = textArray[i].dataset.long;
          textArray[0].dataset.long
        }
      
      


    }
  }

  if (window.location.pathname !== "/index.html" && window.location.pathname !== "/") {
    window.addEventListener('resize', breakMenu, false);
  }

  breakMenu();
  $(window).trigger("resize");  





/*  頁簽 js
  -------------------------------------------------- */
  
  //媽媽教室  login對話框 RWD，點選更多登入選項
  $("[id^='module'] .more").click(function () {
    $(this).parents(".w50").next(".w50").find(".mb30").fadeToggle();
  });


  $(".js-back").click(function () {  //不知為何都會觸發跳轉
    // window.history.back();
  });



/*  各頁面的landing 動畫開關
  -------------------------------------------------- */

  // $(".submenu a").click(function () {
  //   landingAnimationClose = true;
  // });
  // landingAnimationClose = false;



/*  頁簽按鈕 直接跳到頁簽內容
  -------------------------------------------------- */
  // var scrollToSubmenu = sessionStorage.getItem("scrollToSubmenu") || false;
  function showSubmenu() {
    var scrollToSubmenu;
    if (sessionStorage.getItem("scrollToSubmenu") !== null) {
      scrollToSubmenu = sessionStorage.getItem("scrollToSubmenu");
    }

    if (scrollToSubmenu == "scroll down") {
      if ($(window).width() >= 1300  ) {
        window.scrollTo({top: document.getElementById("content").offsetTop - 151});
      } else {
        window.scrollTo({ top: document.getElementById("content").offsetTop - 131 });
      }
    }
    
    sessionStorage.setItem("scrollToSubmenu", "original");

    $(".submenu a,\
      .address__location a,\
      .anchor"
    ).click(function () {
      sessionStorage.setItem("scrollToSubmenu", "scroll down");
    });
  }

  if ($("#index")[0] == undefined) { showSubmenu(); }


    


/*  顯示當前 submenu 樣式
  -------------------------------------------------- */

  var position = window.location.pathname.indexOf(".");
  var currentURL = window.location.pathname.slice(1, position);
  $(`.submenu a[href="${currentURL}.html"]`)
    .parent("li")
    .addClass("active");


} // readyJs End


$(document).ready(function () {readyJs();});






var beforeWidth = $(window).width();

$(window).on('resize', function () {

  if (beforeWidth > 1100 ) {
      
    if ($(window).width() <= 1100 ) {
      location.reload(true);
    }
    console.log(beforeWidth);
  } else {

    if ($(window).width() > 1100) {
      location.reload(true);
    }
    console.log(beforeWidth);
  }

  beforeWidth = $(window).width();
});


$(function () {
  var main = $("main");
  TweenLite.to(main, 0.8, {autoAlpha: 1});
  
})

/* ==========================================================================
            smoothState 插件（ajax載入，減少refresh）
  ==========================================================================*/

// if ($(window).width() >= 1100) {

//   (function ($) {
//     'use strict';
//     $(document).ready(function () {
      
//       // readyJs();
//       var $main = $('#smooth');
//       var tl_smooth = new TimelineLite();

//       $main.smoothState({
//         onStart: {
//           duration: 800,
//           render: function (url, $container) {
//             // $main.addClass("is-exiting");
//             tl_smooth
//             .set("#mask", { x: 0 })
//             .to("#mask", 0.8, { x: "155vw" , ease: Power1.easeInOut });
            
//           }
//         },
//         onReady: {
//           duration: 800,
//           render: function ($container, $newContent) {
//             $container.html($newContent);
//             // $main.removeClass("is-exiting");
//             tl_smooth
//             .set("#mask", { x: "155vw" })
//             .to("#mask", 0.8, { x: "320vw", ease: Power1.easeInOut });
            
//             beforeReady();
//             readyJs();
//           }
//         },
//         anchors: ".menu__cover a, .js-back, .submenu a",
//         blacklist: "#footer", 

//       }).data('smoothState');
//     });

//   }(jQuery));

// } else {
//   document.getElementById("mask").style.display = "none";


//   (function ($) {
//     'use strict';
//     $(document).ready(function () {

//       var $main = $('#smooth');
//       $main.smoothState({
//         onStart: {
//           duration: 400,
//           render: function (url, $container) {
//             $main.addClass("is-exiting");
//           }
//         },
//         onReady: {
//           duration: 400,
//           render: function ($container, $newContent) {
//             $main.removeClass("is-exiting");
//             $container.html($newContent);

//             beforeReady();
//             readyJs();
//           }
//         },
//         anchors: ".menu__cover a, .js-back, .submenu a",//沒有加入的話，首頁的 logo按鈕會失效(原因有待釐清)
//         blacklist: ".drop > a", 


//       }).data('smoothState');
//     });

//   }(jQuery));



// }